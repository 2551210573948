//Browser.js


import React , { Component } from 'react';

import {
    BrowserRouter as Router,
    browserHistory,
    Routes ,
    Route,
    Redirect,
    Link,
    useParams,
    HashRouter,
    withRouter
} from 'react-router-dom';
import {Header,MenuItem,NodeItem} from "../library"
import {SearchForm} from "../SearchForm"

export class Search extends Component
{
    constructor(props) {
      super(props);
      this.state = {
        folder:this.props.match.params.folderid,
        document:null,
        body:null,
        searchresult:null,
        Browse:null
      };
    }


    loadData()
    {
        const API_URL = window.API_URL;
        const url=API_URL + '/api/LoadDocument.ashx';
        const formData = new FormData();
        
        formData.append("ID","0");
        formData.append("folder","1");
         //Send Token ID
         const token=localStorage.getItem('token');
         if(token){formData.append("token",token);};
        fetch(url, {
            method: 'POST',
            body: formData,
            credentials:'include'
        })
        .then(response => response.json())
        .then(result => {
            //console.log(result);
            if(result.User.UserType=='Anonymous')
            {
                console.log("Login Required : " + result.User.UserType);
                //window.location.assign("/");
            }
            this.setState({ document: result.Document });
            //this.setState({ body: result });
        })
        .catch(error => {
            console.error('Error:', error);
        });        
    }

    componentDidMount() {
        this.loadData();
    }




    render()
    {
        
        const isFolder=(it) => it.Type=="مجلد";
        const openFolder=(it)=>{window.location.assign("#/index/" + it.ID);window.location.reload()};
        const openDocument=(it)=>{window.location.assign("#/Document/Edit/" + it.ID);window.location.reload()};
        const totalWidth=this.state.Browse && (this.state.Browse.Columns.reduce((a, b) =>{return a + parseInt(b.width);}, 0));

        
        return (
            
            <>
                <Header title="ميتا دوكيمنت" src="logo.png">
                    <MenuItem title="الصفحة الرئيسية"  src="home24.png" href={ '#/index/0'} />
                    <MenuItem title="تسجيل خروج"  src="logout24.png" href={ '#/'} />
                </Header>
                {/*JSON.stringify(this.state.body)*/}
                <div style={{display:"flex",justifyContent: "space-between"}}>
                    <div style={{width:"24.5%",overflow:"scroll",height:"95vh",overflowY:"auto"}}>
                        <div className='treeCaption'>بحث</div>
                        <div style={{padding:"0px 1rem"}}>

                        { this.state.document && <SearchForm url={window.API_URL +'/api/Search.ashx'} onSuccess={(result)=>{this.setState({Browse:result.Browse})}} template="" name={this.state.document.name} id={this.state.document.documentid}  fields={this.state.document.fields} templates={this.state.document.templates} />/**/} 
                        </div>
                        
                        </div>

      


                    <div style={{width:"75%",overflow:"scroll",height:"95vh"}}>

                        <table style={{minWidth:"75vw",width:totalWidth + 'px'}}>
                                <thead>
                                    <tr>
                                        {this.state.Browse && this.state.Browse.Columns.map((it,i)=>{
                                            return(<th key={i + '_column'} style={{width:it.width + "px"}}>{it.title}</th>)
                                        })}
                                    </tr>                        
                                </thead>
                                <tbody>
                                
                                {this.state.Browse && this.state.Browse.FolderRows.map(it=>{
                                            return(<tr key={it.ID + '_folder'} style={{cursor:"pointer"}} onClick={()=>{ window.location.assign("#/index/" + it.ID);window.location.reload();}}>
                                                {it.Cells.map((value,i)=>{
                                                    return (<td key={i + '_folder_cell'}>{ i===0 ?'📁 ':''} {value}</td>);
                                                })}
                                            </tr>)
                                })}
        
                                {this.state.Browse && this.state.Browse.ContentRows.map(it=>{
                                            return(<tr key={it.ID + '_content'} style={{cursor:"pointer"}} onClick={()=>{ window.location.assign("#/documentview/" + it.ID)}}>
                                                {it.Cells.map((value,i)=>{
                                                    return (<td key={i + '_content_cell'} >{ i===0 ?'📄 ':''}{value}</td>);
                                                })}
                                            </tr>)
                                })}

                                </tbody>
                            </table>

                    </div>
                </div>


            </>
        );
    }
}
