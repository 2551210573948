
import React , { Component } from 'react';
//Header
export class Header extends Component
{
    constructor(props) {
      super(props);
      this.state = {Result:null,  };
    }

    render()
    {
        const headerStyle={
            backgroundColor:"#07668f",
            display:"flex",
            justifyContent: "space-between",
            //maxWidth:"1200px",
            margin:"0px auto",
            padding:"20px"
        };

        const logoBox={
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            padding:"0.5rem"
        }
        const logoStyle={
            display:"inline",
            marginTop:"0px",
            width:"1.5rem",
            height:"1.5rem"
        };
        const logoTextStyle={
            display:"inline",
            fontSize:"1.3rem",
            lineHeight:"1.3rem",
            padding:"0px 0.5rem"
        };
        const childrenStyle={
            display:"flex",
            justifyContent: "flex-end",
            columnGap:"0.4rem"
        };
        //Render
        return (
            <div style={headerStyle}>
                <div style={logoBox}>
                    <img style={logoStyle} src={this.props.src}  />
                    <div className="primaryDark" style={logoTextStyle}>{this.props.title}</div>
                </div>
                <div style={childrenStyle}>{this.props.children}</div>
            </div>
        );
    }
}


export class MenuItem extends Component
{
    constructor(props) {
      super(props);
    }

    render()
    {
        return (
            <a className='activeaccentBackground' style={{display:"block",padding:"0.3rem" ,color:"white",textDecoration:"none",minWidth:"5rem"}} href={this.props.href}>
                <img src={this.props.src} style={{display:"block", margin:"0px auto",height:"1.2rem"}} />
                <div style={{fontSize:"0.7rem",lineHeight:"0.7rem",textAlign:"center"}}>{this.props.title}</div>
            </a>
        );
    }
}

export class MenuButton extends Component
{
    constructor(props) {
      super(props);
    }

    render()
    {
        return (
            <div className='activeaccentBackground'  style={{display:"block",padding:"0.3rem" ,color:"white",textDecoration:"none",cursor:"pointer"}}>
                <img src={this.props.src} style={{display:"block", margin:"0px auto",height:"1.2rem"}} />
                <div style={{fontSize:"0.7rem",lineHeight:"0.7rem"}}>{this.props.title}</div>
            </div>
        );
    }
}



export class NodeItem extends Component
{
    constructor(props) {
      super(props);
      this.state = {data:props.node,current:props.current};
    }

    render()
    {
        const item=this.state.current==this.state.data.ID?<div style={{fontSize:"0.8rem"}} className='accent'>{'📂' + this.state.data.Name}</div>:<a className='light' style={{display:"block",fontSize:"0.8rem"}} href={'#/index/' + this.state.data.ID}>📁 {this.state.data.Name}</a>;
        return (
            <ul style={{padding:"0",margin:"0",listStyle:"none",fontSize:"0.7rem"}}>
                <li style={{padding:"0.3rem",margin:"0px 1rem"}}>
                    {item}
                    {this.state.data.Nodes.map( it =><NodeItem node={it} key={it.ID} current={this.state.current} />)
                    }
                </li>
            </ul>
        );
    }
}

