
import React , { Component } from 'react';

/*
const data=[
    {
        index:0,
        template:"1",
        id:"F__0012",
        value:"xyz",
        caption:"FullName",
        type:"text",
        required:true
    },
    {
        index:1,
        template:"1",
        id:"F__0013",
        value:"0",
        caption:"Year",
        type:"int",
        required:false
    },
    {
        index:2,
        template:"1",
        id:"F__0014",
        value:"",
        caption:"Salary",
        type:"double",
        required:false
    },
    {
        index:3,
        template:"2",
        id:"F__0015",
        value:"",
        caption:"Birth Date",
        type:"date",
        required:false
    },
    {
        index:4,
        template:"2",
        id:"F__0016",
        value:"",
        caption:"Nationality",
        type:"list",
        required:false,
        values:["Truck","Bus","Pickup"]
    },
    {
        index:5,
        template:"3",
        id:"F__0017",
        value:"",
        caption:"First?",
        type:"check",
        required:false
    },
    {
        index:6,
        template:"3",
        id:"F__0018",
        value:"",
        caption:"Description",
        type:"memo",
        required:false
    }
];
*/

export class DocumentForm extends Component
{
    constructor(props) {
      super(props);
      this.state = {
            id:props.id,
            folder:props.folder,
            name:props.name,
            data:props.fields,
            template:props.template,
            templates:props.templates,
            classifications:props.classifications,
            classification:props.classification,
            submit:"حفظ المستند",
            url:props.url,
            error:null,
            document:null,
            onSuccess:props.onSuccess,
            processing:false
        };
    }

    onChangeHandler = index => e => {
        const { data } = this.state
        const re = /^[0-9\b]+$/;
        switch(data[index].type)
        {
            case "int": if(re.test(e.target.value)) data[index].value = e.target.value
            default : data[index].value = e.target.value
        }
       
      this.setState({ data })
    }
   
   

    
    render()
    {
        
        const fetchAndAppendFile=async function fetchAndAppendFile(url) {
            try {
                const response = await fetch(url);
                const blob = await response.blob();
                if(blob.type=='application/pdf')
                {
                    const file = new File([blob], 'document.pdf', { type: blob.type });
                    const att = document.getElementById("document");
                    
                    const dataTransfer = new DataTransfer();
                    dataTransfer.items.add(file);
                    att.files = dataTransfer.files;

                    //console.log(att.files);                    
                }else{
                    console.log("Error:" + url );    
                }
                //console.log(att.files);  
       
                
                //console.log(file);
               
            } catch (error) {
                console.error('Error fetching and appending file:', error);
            }
        };

        var id = "";

        const getData=async function getData() {
            const url = "http://localhost:50555";
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`Response status: ${response.status}`);
                }
                const json = await response.json();
                if(json.id){
                    id = json.id; 
                    console.log("Response ID:"+ id);                   
                }else{
                    console.log("Old ID:"+ id); 
                }

                
            } catch (error) {
                console.error(error.message);
            }
        };

        const  loadData= function () {
            var url = "http://localhost:50555/?key=" + id;
            fetchAndAppendFile(url);
        };


        const handleSubmit = (event) => {
            event.preventDefault();
            const url=this.state.url;
            const formData = new FormData();
            formData.append("folder",this.state.folder);
            formData.append("id",this.state.id);
            formData.append("name",this.state.name);
            formData.append("templateid",this.state.template);
            formData.append("classificationid",this.state.classification);
            formData.append("document",this.state.document);
            const att = document.getElementById("document");
            //console.log(att);
            if(this.state.document==null){
               formData.append("document",att.files[0]); 
            }
            
            const token=localStorage.getItem('token');
            if(token){formData.append("token",token);};

            for (let index = 0; index < this.state.data.length; index++) {
                const el = this.state.data[index];
                formData.append(el.id,el.value)
                
            }
            this.setState({processing:true}) 
            fetch(url, {
                method: 'POST',
                body: formData,
                credentials:'include'
            })
            .then(response => response.json())
            .then(result => {
                if(result.ErrorMessage!=''){
                    if(result.User.UserType=="Anonymous"){
                        console.log("Login Required : " + result.User.UserType);
                        window.location='#/'
                    }else{
                       this.setState({error:result.ErrorMessage}) 
                    }
                    
                }else{
                  this.state.onSuccess(result);
                }
            })
            .catch(error => {
                this.setState({error:"لا يمكن الإتصال بالموقع"})
            }); 

        };
        
        const fields=this.state.data
                .filter(it => it.template==this.state.template)
                .map((it,i)=>{
                    if(it.type=="text" ||  it.type=="memo")
                    {
                        return (
                            <div key={it.index}>
                                <div className='caption'>{it.caption}<span className='accent'>{it.required?" *":""}</span></div>
                                <div><input id={it.id}  value={it.value}  onChange={this.onChangeHandler(it.index)} type="text" /></div>
                            </div>
                        );
                    }
                    if(it.type=="int" )
                    {
                        return (
                            <div key={it.index}>
                                <div className='caption'>{it.caption}<span className='accent'>{it.required?" *":""}</span></div>
                                <div><input id={it.id}  value={it.value}  onChange={this.onChangeHandler(it.index)} type="text" onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/></div>
                            </div>
                        );
                    }
                    if(it.type=="double" )
                    {
                        return (
                            <div key={it.index}>
                                <div className='caption'>{it.caption}<span className='accent'>{it.required?" *":""}</span></div>
                                <div><input id={it.id}  value={it.value}  onChange={this.onChangeHandler(it.index)} type="text" onKeyPress={(event) => {if (!/[0-9\.]/.test(event.key)) {event.preventDefault();}}}/></div>
                            </div>
                        );
                    }
                    if(it.type=="date")
                    {
                        return (
                            <div key={it.index}>
                                <div className='caption'>{it.caption}<span className='accent'>{it.required?" *":""}</span></div>
                                <div><input id={it.id}  value={it.value}  onChange={this.onChangeHandler(it.index)} type="date" /></div>
                            </div>
                        );
                    }
                    if(it.type=="list")
                    {
                        return (
                            <div key={it.index}>
                                <div className='caption'>{it.caption}<span className='accent'>{it.required?" *":""}</span></div>
                                <div>
                                    <select id={it.id}   value={it.value} onChange={this.onChangeHandler(it.index)}>
                                        {it.values.map(item=><option value={item}>{item}</option>)}
                                    </select>
                                </div>
                            </div>
                        );
                    }
                    if(it.type=="check")
                    {
                        return (
                            <div key={it.index}>
                                <div className='caption'>{it.caption}<span className='accent'>{it.required?" *":""}</span></div>
                                <div>
                                    <select id={it.id}   value={it.value} onChange={this.onChangeHandler(it.index)}>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                </div>
                            </div>
                        );
                    }
                    return null;
                });

        return (
            <>
                <form onSubmit={handleSubmit}>
                    <div>
                        <div className='caption'>الاسم<span className='accent'> *</span></div>
                        <div><input id="name"  value={this.state.name} onChange={(e) => {this.setState(() => ({name: e.target.value}));}} type="text" /></div>
                    </div>
                    <div>
                        
                        <div className='caption'>القالب</div>
                        <select  onChange={(e) => {this.setState(() => ({template: e.target.value}));}} id="template" value={this.state.template}>
                            {
                                this.state.templates.map(it=><option value={it.id}>{it.title}</option>)
                            }
                        </select>
                    </div>
                    
                  <div>
                        {fields}
                        <div>
                                <div className='caption'>المستند</div>
                                
                                <div><input id='document' type="file"   onChange={e => { this.setState({ document: e.target.files[0] });console.log("Changing happend!"); console.log(e.target.files); }}/></div>
                                <div style={{display:"flex",justifyContent:"space-betweeen"}}>
                                    <img style={{display:"block",margin:"0px auto",cursor:"pointer"}} src="from_scanner_32.png" onClick={ e => {getData();}}/>
                                    <img style={{display:"block",margin:"0px auto",cursor:"pointer"}} src="download.png" onClick={ e => {loadData();}}/>
                                </div>
                            </div>
                    </div>
                    <div>
                        
                        <div className='caption'>مستوى السرية</div>
                        <select  onChange={(e) => {this.setState(() => ({classification: e.target.value}));}} id="classification" value={this.state.classification}>
                            {
                                this.state.classifications.map(it=><option value={it.id}>{it.title}</option>)
                            }
                        </select>
                    </div>   
                    <div>
                        {this.state.error && <div className='accentBackground' style={{padding:"0.8rem",margin:"0.3rem 0px",textAlign:"center",color:"white"}}>{this.state.error && '⚠️ '}{this.state.error}</div>}
                        <div style={{display:"flex",justifyContent:"space-between"}}>
                            {!this.state.processing && <button style={{width:"70%"}}>{this.state.submit}</button>}
                            <button style={{width:"28%"}}  className='accent' onClick={()=>{window.history.back()}}>إلغاء الأمر</button>
                        </div>
                    </div>
                </form>
                <div>
                </div>
            </>
        );
    }
}