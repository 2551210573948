

//http://localhost:53888/api/ListRepositories.ashx

import React , { Component } from 'react';

import { FormCreator } from '../FormCreator';
import {Header} from "../library"
 
export class Login extends Component
{
    constructor(props) {
      super(props);
      this.state = {
        data:null
      };
    }

    // componentDidMount(){
    //     const requestOptions = {
    //         method:'GET',
    //         headers:{'Content-Type': 'application/json'},
    //     };
    //     fetch('http://localhost:53888/api/ListRepositories.ashx', requestOptions)
    //         .then(response => response.json())
    //         .then(data => this.setState({data}));


    // }
    componentDidMount()
    {
      const API_URL = window.API_URL;
      const url=API_URL + '/api/ListRepositories.ashx';
      const formData = new FormData();
      
      fetch(url, {
        method: 'POST',
        body: formData,
        credentials:'include'
      })
      .then(response => response.json())
      .then(result => {

        this.setState({ data: result });
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }

    createTemplatesField(){
       return this.state.data.map(it=>{return {id:it.ID,title:it.Name}})
    }
    render()
    {
      const API_URL = window.API_URL;
      const url=API_URL + '/api/Login.ashx';
        
        const fields =[
            {
                id:"repository",
                value:"3",
                caption:"المستودع",
                type:"list",
                listvalues:this.state.data,
                required:true
            },
            {

                id:"username",
                value:"",
                caption:"اسم المستخدم",
                type:"text",
                required:true
            },
            {
                id:"pwd",
                value:"",
                caption:"كلمة المرور",
                type:"password",
                required:true
            }
        ]

        return (
            <div>
                <Header title="ميتا دوكيمنت" src="logo.png"></Header>
                <div style={{maxWidth:"400px",margin:"0px auto"}}>
                  <div style={{width:"100%",padding:"20px 0px"}}><img src="login64.png" style={{margin:"0px auto",display:"block",paddingTop:"60px"}}/></div>
                  {this.state.data && <FormCreator submit="تسجيل دخول" data={fields} url={url} onSuccess={(result)=>{ localStorage.setItem('token', result.TokenID) ;window.location='#/index/0';}} />}   
                </div>
            </div>
        );
    }
}