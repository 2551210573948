//Browser.js


import React , { Component } from 'react';

import {
    BrowserRouter as Router,
    browserHistory,
    Routes ,
    Route,
    Redirect,
    Link,
    useParams,
    HashRouter,
    withRouter
} from 'react-router-dom';
import {Header,MenuItem,NodeItem} from "../library"

export class Browser extends Component
{
    constructor(props) {
      super(props);
      this.state = {
        id:this.props.match.params.id,
        body:null
      };
      console.log(this.props);
    }

    loadData()
    {
        const API_URL = window.API_URL;
        const url=API_URL + '/api/ListContent.ashx';
        const formData = new FormData();
        formData.append("ID",this.state.id)
        const token=localStorage.getItem('token');
        if(token){formData.append("token",token);};
        fetch(url, {
            method: 'POST',
            body: formData,
            credentials:'include'
        })
        .then(response => response.json())
        .then(result => {
            console.log(result);
            if(result.User.UserType=='Anonymous')
            {
                console.log("Login Required : " + result.User.UserType);
                window.location.assign("/");
            }
            this.setState({ body: result });
        })
        .catch(error => {
            console.error('Error:', error);
        });        
    }

    componentDidMount() {
        this.loadData();
    }
    componentWillUnmount() {
        this.setState({ body: null });
    }



    render()
    {
        
        const isFolder=(it) => it.Type=="مجلد";
        const openFolder=(it)=>{window.location.assign("#/index/" + it.ID);window.location.reload()};
        const openDocument=(it)=>{window.location.assign("#/Document/Edit/" + it.ID);window.location.reload()};
        const totalWidth=this.state.body && (this.state.body.Browse.Columns.reduce((a, b) =>{return a + parseInt(b.width);}, 0));

        return (
            
            <>
                <Header title="ميتا دوكيمنت" src="logo.png">
                    <MenuItem title="بحث"  src="search24.png" href={'#/search'} />
                    <MenuItem title="مستند جديد"  src="newdocument24.png" href={'#/documentadd/' + this.state.id} />
                    <MenuItem title="مجلد جديد"  src="newfolder24.png" href={'#/folderadd/' + this.state.id} />
                    <MenuItem title="إعادة تسمية المجلد"  src="editfolder24.png" href={ '#/folderedit/'+ this.state.id} />
                    {this.state.body && <MenuItem title="المجلد الأب"  src="back24.png"  href={ "#/index/" + this.state.body.ParentID } />}
                    <MenuItem title="تسجيل خروج"  src="logout24.png" href={ '/'} />
                </Header>
                {/*JSON.stringify(this.state.body)*/}
                <div style={{display:"flex",justifyContent: "space-between"}}>
                    <div style={{width:"24.5%",overflow:"scroll",height:"91vh",overflowY:"auto"}}>
                        <div className='treeCaption'>شجرة المستندات</div>
                        {this.state.body && this.state.body.Tree.map(it=>{
                                    return(<NodeItem node={it} key={it.ID} current={this.state.id}/>)
                                })}
                        </div>




                    <div style={{width:"75%",overflow:"scroll",height:"91vh"}}>
                        <table style={{minWidth:"75vw",width:totalWidth + 'px'}}>
                            <thead>
                                <tr>
                                    {this.state.body && this.state.body.Browse.Columns.map((it,i)=>{
                                        return(<th key={i + '_column'} style={{width:it.width + "px"}}>{it.title}</th>)
                                    })}
                                </tr>                        
                            </thead>
                            <tbody>
                            
                            {this.state.body && this.state.body.Browse.FolderRows.map(it=>{
                                        return(<tr key={it.ID + '_folder'} style={{cursor:"pointer"}} onClick={()=>{ window.location.assign("#/index/" + it.ID);window.location.reload();}}>
                                            {it.Cells.map((value,i)=>{
                                                return (<td key={i + '_folder_cell'}>{ i===0 ?'📁 ':''} {value}</td>);
                                            })}
                                        </tr>)
                             })}
     
                            {this.state.body && this.state.body.Browse.ContentRows.map(it=>{
                                        return(<tr key={it.ID + '_content'} style={{cursor:"pointer"}} onClick={()=>{ window.location.assign("#/documentview/" + it.ID)}}>
                                            {it.Cells.map((value,i)=>{
                                                return (<td key={i + '_content_cell'} >{ i===0 ?'📄 ':''}{value}</td>);
                                            })}
                                        </tr>)
                             })}

                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}



//const BrowserX = withRouter(Browser);

//export default  withRouter(Browser);