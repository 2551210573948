//DocumentViewer


import React , { Component } from 'react';
import {Header,MenuItem} from "../library"
import {DocumentForm} from "../DocumentForm"
import {
    BrowserRouter as Router,
    browserHistory,
    Routes ,
    Route,
    Redirect,
    Link,
    useParams,
    HashRouter,
    withRouter
  } from 'react-router-dom';


export class DocumentViewer extends Component
{
    constructor(props) {
      super(props);
      this.state = {
        id:this.props.match.params.id,
        document:null,
      };
      //console.log(this.props);
    }



    componentDidMount() {
        const API_URL = window.API_URL;
        const url=API_URL + '/api/ViewDocument.ashx';
        const formData = new FormData();
        formData.append("ID",this.state.id);
        //Send Token ID
        const token=localStorage.getItem('token');
        if(token){formData.append("token",token);};

        fetch(url, {
            method: 'POST',
            body: formData,
            credentials:'include'
        })
        .then(response => response.json())
        .then(result => {
            //console.log(result);
            if(result.User.UserType=='Anonymous')
            {
                console.log("Login Required : " + result.User.UserType);
                //window.location.assign("/");
            }
            this.setState({ document: result.Document });
        })
        .catch(error => {
            //console.error('Error:', error);
        });  
    }




    render()
    {
        
        const documentStyle={
            width:'100%',
            height:'80vh',
            border:"0px"
          }
          const token=localStorage.getItem('token');

        return (
            
            <>
                <Header title="ميتا دوكيمنت" src="logo.png">
                    {
                        this.state.document && 
                        <MenuItem title="تعديل المستند"  src="editdocument24.png" href={ '#/documentedit/' + this.state.document.documentid} />
                    } 
                    {
                        this.state.document && 
                        <MenuItem title="عودة إلى المجلد"  src="back24.png" href={ '#/index/' + this.state.document.folderid} />
                    } 
                    <MenuItem title="الصفحة الرئيسية"  src="home24.png" href={ '#/index/0'} />
                    <MenuItem title="تسجيل خروج"  src="logout24.png" href={ '#/'} />
                        
                </Header>
                <div style={{display:"flex",justifyContent: "space-between"}}>
                    <div style={{width:"24.5%",overflow:"scroll",overflowY:"auto"}}>
                        <div className='treeCaption'>الحقول</div>
                        <div style={{padding:"0px 1rem"}}>
                            {
                                this.state.document && 
                                <div>
                                    <div className='caption'>الاسم</div>
                                    <div className='input-value'>{this.state.document.name}</div>
                                </div>
                            }
                            {
                                this.state.document && 
                                <div>
                                    <div className='caption'>القالب</div>
                                    <div className='input-value'>{this.state.document.template}&nbsp;</div>
                                </div>
                            }
                            { this.state.document && this.state.document.fields.map(it=>{
                              return (
                                <div>
                                    <div className='caption'>{it.caption}</div>
                                    <div className='input-value'>{it.value}&nbsp;</div>
                                </div>
                            );
                            })} 
                            </div>
                            <div className='treeCaption'>عام</div>
                            <div style={{padding:"0px 1rem"}}>

                                {  this.state.document && 
                                    <div>
                                        <div className='caption'>الباركود</div>
                                        <div className='input-value'>{this.state.document.barcode}&nbsp;</div>
                                    </div>
                                }
                                {  this.state.document && 
                                    <div>
                                        <div className='caption'>مستوى السرية</div>
                                        <div className='input-value'>{this.state.document.classification}&nbsp;</div>
                                    </div>
                                }
                                {  this.state.document && 
                                    <div>
                                        <div className='caption'>عدد الصفحات</div>
                                        <div className='input-value'>{this.state.document.pages}&nbsp;</div>
                                    </div>
                                }                           
                                {  this.state.document && 
                                    <div>
                                        <div className='caption'>حجم الملف</div>
                                        <div className='input-value'>{this.state.document.size}&nbsp;</div>
                                    </div>
                                }
                                {  this.state.document && 
                                    <div>
                                        <div className='caption'>الإنشاء</div>
                                        <div className='input-value' dir='ltr'>📅 {this.state.document.creationdate}<br />👤 {this.state.document.creator}</div>
                                    </div>
                                }  
                                {  this.state.document && 
                                    <div>
                                        <div className='caption'>آخر تعديل</div>
                                        <div className='input-value' dir='ltr'>📅 {this.state.document.modificationdate}<br />👤 {this.state.document.modifier}</div>
                                    </div>
                                }                              
                                                        
                            </div>

                    </div>
                    <div style={{width:"75%",overflow:"scroll",overflowY:"auto"}}>
                        <div className='treeCaption'>المستند</div>
                        {
                            this.state.document && 
                            this.state.document.file!=''  && 
                            (this.state.document.file.endsWith('.pdf')  || this.state.document.file.endsWith('.png')  ||  this.state.document.file.endsWith('.jpg')) &&
                            <iframe className="holds-the-iframe" style={documentStyle}  src={window.API_URL  + '/api/Download.ashx?ID=' + (this.props.match.params.id?this.props.match.params.id:0) + '&token=' + token }>هذا المتصفح لا يدعم عرض ملفات البي دي إف</iframe>
                        }
                        {
                            this.state.document && 
                            this.state.document.file!=''  &&
                            (!this.state.document.file.endsWith('.pdf')  && !this.state.document.file.endsWith('.png')   &&  !this.state.document.file.endsWith('.jpg'))   &&
                            <div style={{width:"100%", display:"flex",justifyContent:"center",height:"70vh",alignItems:"center",padding:"2rem"}}>
                                <a  className='light' href={window.API_URL  + '/api/Download.ashx?ID=' + (this.props.match.params.id?this.props.match.params.id:0)  + '&token=' + token}>
                                    <img style={{display:"block","margin":"0px auto",paddingTop:"1rem"}} src="download128.png" />
                                    <div style={{textAlign:"center",fontSize:"0.8rem",padding:"0.5rem 0px"}}>{this.state.document.file}</div>
                                    <div style={{textAlign:"center",padding:"0.5rem",fontSize:"1.2rem"}}>تنزيل الملف</div>
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </>
        );
    }
}
