
import React , { Component } from 'react';


export class FormCreator extends Component
{
    constructor(props) {
      super(props);
      this.state = {
            data:props.data,
            submit:props.submit,
            url:props.url,
            error:null,
            onSuccess:props.onSuccess,
            hiddens:props.hiddens
        };
    }


    post()
    {

    }

    onChangeHandler = index => e => {
      const { data } = this.state
      data[index].value = e.target.value
      this.setState({ data })
    }
   
    render()
    {
        
        const handleSubmit = (event) => {
            event.preventDefault();
            const url=this.state.url;
            const formData = new FormData();
            if(this.state.hiddens){
                for (let index = 0; index < this.state.hiddens.length; index++) {
                    const el = this.state.hiddens[index];
                    formData.append(el.id,el.value)
                }
            }

            for (let index = 0; index < this.state.data.length; index++) {
                const el = this.state.data[index];
                formData.append(el.id,el.value)
            }

            //hiddens
            fetch(url, {
                method: 'POST',
                body: formData,
                credentials:'include'
            })
            .then(response => response.json())
            .then(result => {
                if(result.ErrorMessage!=''){
                    this.setState({error:result.ErrorMessage})
                }else{
                   // this.setState({error:"OK"})
                  //  console.log(result);
                  this.state.onSuccess(result);
                  console.log("Refrsh");
                  //if(this.props.refresh) {window.location.reload();console.log("Refrsh");};
                }
            })
            .catch(error => {
                this.setState({error:"لا يمكن الإتصال بالموقع"})
            }); 

        };
        
        const fields=this.state.data
                .map((it,i)=>{
                    if(it.type=="text" ||  it.type=="memo")
                    {
                        return (
                            <div key={i}>
                                <div className='caption'>{it.caption}<span className='accent'>{it.required?" *":""}</span></div>
                                <div><input id={it.id}  value={it.value}  onChange={this.onChangeHandler(i)} type="text" /></div>
                            </div>
                        );
                    }
                    if(it.type=="password")
                    {
                        return (
                            <div key={i}>
                                <div className='caption'>{it.caption}<span className='accent'>{it.required?" *":""}</span></div>
                                <div><input id={it.id}  value={it.value}  onChange={this.onChangeHandler(i)} type="password" /></div>
                            </div>
                        );
                    }
                    if(it.type=="int" )
                    {
                        return (
                            <div key={i}>
                                <div className='caption'>{it.caption}<span className='accent'>{it.required?" *":""}</span></div>
                                <div><input id={it.id}  value={it.value}  onChange={this.onChangeHandler(i)} type="text" onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/></div>
                            </div>
                        );
                    }
                    if(it.type=="double" )
                    {
                        return (
                            <div key={i}>
                                <div className='caption'>{it.caption}<span className='accent'>{it.required?" *":""}</span></div>
                                <div><input id={it.id}  value={it.value}  onChange={this.onChangeHandler(i)} type="text" onKeyPress={(event) => {if (!/[0-9\.]/.test(event.key)) {event.preventDefault();}}}/></div>
                            </div>
                        );
                    }
                    if(it.type=="date")
                    {
                        return (
                            <div key={i}>
                                <div className='caption'>{it.caption}<span className='accent'>{it.required?" *":""}</span></div>
                                <div><input id={it.id}  value={it.value}  onChange={this.onChangeHandler(i)} type="date" /></div>
                            </div>
                        );
                    }
                    if(it.type=="textlist")
                    {
                        return (
                            <div key={i}>
                                <div className='caption'>{it.caption}<span className='accent'>{it.required?" *":""}</span></div>
                                <div>
                                    <select id={it.id}   value={it.value} onChange={this.onChangeHandler(i)}>
                                    <option value=""></option>
                                        {it.values.map(item=><option value={item}>{item}</option>)}
                                    </select>
                                </div>
                            </div>
                        );
                    }
                    if(it.type=="check")
                    {
                        return (
                            <div key={i}>
                                <div className='caption'>{it.caption}<span className='accent'>{it.required?" *":""}</span></div>
                                <div>
                                    <select id={it.id}   value={it.value} onChange={this.onChangeHandler(i)}>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                </div>
                            </div>
                        );
                    }
                    if(it.type=="list")
                    {
                        return (
                            <div key={i}>
                                <div className='caption'>{it.caption}<span className='accent'>{it.required?" *":""}</span></div>
                                <div>
                                    <select id={it.id}   value={it.value} onChange={this.onChangeHandler(i)}>
                                        <option value="0"></option>
                                        {it.listvalues.map((item,i)=><option key={i} value={item.id}>{item.title}</option>)}
                                    </select>
                                </div>
                            </div>
                        );
                    }
                    return null;
                });

        return (
            <>
                <form onSubmit={handleSubmit}>

                    <div>
                        {fields}
                    </div>
                    <div>
                    {this.state.error && <div className='accentBackground' style={{padding:"0.8rem",margin:"0.3rem 0px",textAlign:"center",color:"white"}}>{this.state.error}</div>}
                        <button>{this.state.submit}</button>
                    </div>
                </form>
            </>
        );
    }
}


/*

const data=[
  {
      index:0,
      template:"1",
      id:"F__0012",
      value:"xyz",
      caption:"FullName",
      type:"text",
      required:true
  },
  {
      index:1,
      template:"1",
      id:"F__0013",
      value:"0",
      caption:"Year",
      type:"int",
      required:false
  },
  {
      index:2,
      template:"1",
      id:"F__0014",
      value:"",
      caption:"Salary",
      type:"double",
      required:false
  },
  {
      index:3,
      template:"2",
      id:"F__0015",
      value:"",
      caption:"Birth Date",
      type:"date",
      required:false
  },
  {
      index:4,
      template:"2",
      id:"F__0016",
      value:"Pickup",
      caption:"Nationality",
      type:"textlist",
      required:false,
      values:["Truck","Bus","Pickup"]
  },
  {
      index:5,
      template:"3",
      id:"F__0017",
      value:"",
      caption:"First?",
      type:"check",
      required:false
  },
  {
      index:6,
      template:"3",
      id:"F__0018",
      value:"",
      caption:"Description",
      type:"memo",
      required:false
  },
  {
      index:7,
      template:"3",
      id:"F__0019",
      value:"",
      caption:"Enter password",
      type:"password",
      required:false
  },
  {
    index:8,
    template:"1",
    id:"F__0019",
    value:"3",
    caption:"Year",
    type:"list",
    listvalues:[
      {id:"1",title:"One"},
      {id:"2",title:"Two"},
      {id:"3",title:"Three"}
    ],
    required:false
  }
];


*/