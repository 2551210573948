//FolderAdd


import React , { Component } from 'react';

import { FormCreator } from '../FormCreator';
import {Header,MenuItem} from "../library"
 
export class FolderAdd extends Component
{
    constructor(props) {
      super(props);
      this.state = {
        folder:this.props.match.params.folder,
      };
    }
  
    render()
    {
      const API_URL = window.API_URL;
      const url=API_URL + '/api/AddFolder.ashx';
        //Send Token ID
        const token=localStorage.getItem('token');
   
        
        const fields =[
            {token:token},
            {

                id:"name",
                value:"",
                caption:"أدخل اسم المجلد",
                type:"text",
                required:true
            }
        ]

        return (
            <div>
                <Header title="ميتا دوكيمنت" src="logo.png">
                    <MenuItem title="الصفحة الرئيسية"  src="home24.png" href={ '#/index/0'} />
                    <MenuItem title="تسجيل خروج"  src="logout24.png" href={ '#/'} />
                </Header>
                <div style={{maxWidth:"400px",margin:"0px auto"}}>
                  <div style={{width:"100%",padding:"20px 0px"}}><img src="login64.png" style={{margin:"0px auto",display:"block",paddingTop:"60px"}}/></div>
                  {this.state.folder && <FormCreator submit="حفظ" data={fields} hiddens={[{id:"folder",value:this.state.folder},{id:"token",value:token}]} url={url} onSuccess={(result)=>{window.location='#/index/' + this.state.folder }} />}   
                </div>
            </div>
        );
    }
}