//SearchForm.js



import React , { Component } from 'react';


export class SearchForm extends Component
{
    constructor(props) {
      super(props);
      this.state = {
            name:props.name,
            data:props.fields,
            template:props.template,
            templates:props.templates,
            submit:"بحث",
            url:props.url,
            error:null,
            onSuccess:props.onSuccess
        };
    }

    onChangeHandler = index => e => {
        const { data } = this.state
        const re = /^[0-9\b]+$/;
        switch(data[index].type)
        {
            case "int": if(re.test(e.target.value)) data[index].value = e.target.value
            default : data[index].value = e.target.value
        }
       
      this.setState({ data })
    }
   
    

    render()
    {
        
        const handleSubmit = (event) => {
            event.preventDefault();
            const url=this.state.url;
            const formData = new FormData();
            formData.append("name",this.state.name);
            formData.append("templateid",this.state.template);
            //Send Token ID
            const token=localStorage.getItem('token');
            if(token){formData.append("token",token);};

            for (let index = 0; index < this.state.data.length; index++) {
                const el = this.state.data[index];
                formData.append(el.id,el.value)
            }
            console.log("SEND");
            fetch(url, {
                method: 'POST',
                body: formData,
                credentials:'include'
            })
            .then(response => response.json())
            .then(result => {
                console.log("To JSON");
                if(result.ErrorMessage!=''){
                    if(result.User.UserType=="Anonymous"){
                        console.log("Login Required : " + result.User.UserType);
                        window.location='#/';
                    }else{
                       this.setState({error:result.ErrorMessage}) 
                    }
                    
                }else{
                  console.log("****");
                  this.state.onSuccess(result);
                }
            })
            .catch(error => {
                this.setState({error:"لا يمكن الإتصال بالموقع"})
            }); 

        };
        
        const fields=this.state.data
                .filter(it => it.template==this.state.template)
                .map((it,i)=>{
                    if(it.type=="text" ||  it.type=="memo")
                    {
                        return (
                            <div key={it.index}>
                                <div className='caption'>{it.caption}</div>
                                <div><input id={it.id}  value={it.value}  onChange={this.onChangeHandler(it.index)} type="text" /></div>
                            </div>
                        );
                    }
                    if(it.type=="int" )
                    {
                        return (
                            <div key={it.index}>
                                <div className='caption'>{it.caption}</div>
                                <div><input id={it.id}  value={it.value}  onChange={this.onChangeHandler(it.index)} type="text" onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/></div>
                            </div>
                        );
                    }
                    if(it.type=="double" )
                    {
                        return (
                            <div key={it.index}>
                                <div className='caption'>{it.caption}</div>
                                <div><input id={it.id}  value={it.value}  onChange={this.onChangeHandler(it.index)} type="text" onKeyPress={(event) => {if (!/[0-9\.]/.test(event.key)) {event.preventDefault();}}}/></div>
                            </div>
                        );
                    }
                    if(it.type=="date")
                    {
                        return (
                            <div key={it.index}>
                                <div className='caption'>{it.caption}</div>
                                <div><input id={it.id}  value={it.value}  onChange={this.onChangeHandler(it.index)} type="date" /></div>
                            </div>
                        );
                    }
                    if(it.type=="list")
                    {
                        return (
                            <div key={it.index}>
                                <div className='caption'>{it.caption}</div>
                                <div>
                                    <select id={it.id}   value={it.value} onChange={this.onChangeHandler(it.index)}>
                                        {it.values.map(item=><option value={item}>{item}</option>)}
                                    </select>
                                </div>
                            </div>
                        );
                    }
                    if(it.type=="check")
                    {
                        return (
                            <div key={it.index}>
                                <div className='caption'>{it.caption}</div>
                                <div>
                                    <select id={it.id}   value={it.value} onChange={this.onChangeHandler(it.index)}>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                </div>
                            </div>
                        );
                    }
                    return null;
                });

        return (
            <>
                <form onSubmit={handleSubmit}>
                    <div>
                        <div className='caption'>الاسم</div>
                        <div><input id="name"  value={this.state.name} onChange={(e) => {this.setState(() => ({name: e.target.value}));}} type="text" /></div>
                    </div>
                    <div>
                        
                        <div className='caption'>القالب</div>
                        <select  onChange={(e) => {this.setState(() => ({template: e.target.value}));}} id="template" value={this.state.template}>
                            {
                                this.state.templates.map(it=><option value={it.id}>{it.title}</option>)
                            }
                        </select>
                    </div>
                    <div>
                        {fields}
                    </div>
                    <div>
                        {this.state.error && <div className='accentBackground' style={{padding:"0.8rem",margin:"0.3rem 0px",textAlign:"center",color:"white"}}>{this.state.error && '⚠️ '}{this.state.error}</div>}
                        <button>{this.state.submit}</button>
                        
                    </div>
                </form>
                <div>
                </div>
            </>
        );
    }
}