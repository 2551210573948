//DocumentAdd


import React , { Component } from 'react';
import {Header,MenuItem} from "../library"
import {DocumentForm} from "../DocumentForm"
import {
    BrowserRouter as Router,
    browserHistory,
    Routes ,
    Route,
    Redirect,
    Link,
    useParams,
    HashRouter,
    withRouter
  } from 'react-router-dom';


export class DocumentAdd extends Component
{
    constructor(props) {
      super(props);
      this.state = {
        folder:this.props.match.params.folderid,
        document:null,
      };
      console.log(this.props);
    }

    loadData()
    {
        const API_URL = window.API_URL;
        const url=API_URL + '/api/LoadDocument.ashx';
        const formData = new FormData();
        formData.append("ID","0")
        formData.append("folder",this.state.folder)
        formData.append("ID",this.state.id)
        //Send Token ID
        const token=localStorage.getItem('token');
        if(token){formData.append("token",token);};

        fetch(url, {
            method: 'POST',
            body: formData,
            credentials:'include'
        })
        .then(response => response.json())
        .then(result => {
            //console.log(result);
            if(result.User.UserType=='Anonymous')
            {
                console.log("Login Required : " + result.User.UserType);
                //window.location.assign("/");
            }
            this.setState({ document: result.Document });
        })
        .catch(error => {
            //console.error('Error:', error);
        });        
    }

    componentDidMount() {
        this.loadData();
    }




    render()
    {
        


        return (
            
            <>
                <Header title="ميتا دوكيمنت" src="logo.png">
                    <MenuItem title="الصفحة الرئيسية"  src="home24.png" href={ '#/index/0'} />
                    <MenuItem title="تسجيل خروج"  src="logout24.png" href={ '#/'} />
                </Header>
                {/*JSON.stringify(this.state.document)*/}
                <div style={{maxWidth:"550px",margin:"0px auto"}}>
                <div className='treeCaption'>📄 إضافة مستند جديد</div>
                { this.state.document && <DocumentForm url={window.API_URL +'/api/AddContent.ashx'} onSuccess={(result)=>{/*window.location='#/index/' + this.state.folder*/ window.location='#/documentview/' + result.ID }} template={this.state.document.templateid} name={this.state.document.name} id={this.state.document.documentid} folder={this.state.folder} fields={this.state.document.fields} templates={this.state.document.templates} classification={""} classifications={this.state.document.classifications} />/**/} 
                </div>
            </>
        );
    }
}
