//import logo from './logo.svg';
//import './App.css';
import './style.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
  useParams,
  HashRouter
} from 'react-router-dom';
import {Login} from "./Pages/Login";
import {Browser} from "./Pages/Browser";
import {DocumentViewer} from "./Pages/DocumentViewer";
import {DocumentEdit} from "./Pages/DocumentEdit";
import {DocumentAdd} from "./Pages/DocumentAdd";
import {FolderAdd} from "./Pages/FolderAdd";
import {FolderEdit} from "./Pages/FolderEdit";
import {Search} from "./Pages/Search";

import {Header,IconMenu,IconMenuItem} from "./library"
//import {FormBuilder} from "./DocumentForm"
import {FormCreator} from "./FormCreator"

window.onhashchange = function() {
  window.location.reload();
}

function App() {
  return (

    <HashRouter basename={process.env.PUBLIC_URL}>
    <Switch>
      <Route exact path='/' component={Login} />
      <Route path='/index/:id' component={Browser} />
      
      <Route path='/documentview/:id' component={DocumentViewer} />
      <Route path='/documentedit/:id' component={DocumentEdit} />
      <Route path='/documentadd/:folderid' component={DocumentAdd} />
      <Route path='/folderadd/:folder' component={FolderAdd} />
      <Route path='/folderedit/:folder' component={FolderEdit} />
      <Route path='/search' component={Search} />
    </Switch>
  </HashRouter>


  );
}

export default App;
