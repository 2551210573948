

import React , { Component } from 'react';

import { FormCreator } from '../FormCreator';
import {Header,MenuItem} from "../library"
 
export class FolderEdit extends Component
{
    constructor(props) {
      super(props);
      this.state = {
        folder:this.props.match.params.folder,
        name:null
      };
    }
  
    loadData()
    {
        const API_URL = window.API_URL;
        const url=API_URL + '/api/LoadFolder.ashx';
        const formData = new FormData();
        formData.append("ID",this.state.folder);
        //Send Token ID
        const token=localStorage.getItem('token');
        if(token){formData.append("token",token);};
        fetch(url, {
            method: 'POST',
            body: formData,
            credentials:'include'
        })
        .then(response => response.json())
        .then(result => {
            //console.log(result);
            if(result.User.UserType=='Anonymous')
            {
                console.log("Login Required : " + result.User.UserType);
                //window.location.assign("/");
            }
            this.setState({ name: result.FolderName });
        })
        .catch(error => {
            console.error('Error:', error);
        });        
    }

    componentDidMount() {
        this.loadData();
    }
    render()
    {
      const API_URL = window.API_URL;
      const url=API_URL + '/api/EditFolder.ashx';
        //Send Token ID
        const token=localStorage.getItem('token');
        const fields =[{id:"name",value:this.state.name,caption:"أدخل اسم المجلد",type:"text",required:true}]

        return (
            <div>
                <Header title="ميتا دوكيمنت" src="logo.png">
                    <MenuItem title="الصفحة الرئيسية"  src="home24.png" href={ '#/index/0'} />
                    <MenuItem title="تسجيل خروج"  src="logout24.png" href={ '#/'} />
                </Header>
                <div style={{maxWidth:"400px",margin:"0px auto"}}>
                  <div style={{width:"100%",padding:"20px 0px"}}><img src="login64.png" style={{margin:"0px auto",display:"block",paddingTop:"60px"}}/></div>
                  {this.state.name && <FormCreator submit="حفظ" data={[{id:"name",value:this.state.name,caption:"أدخل اسم المجلد",type:"text",required:true}]} hiddens={[{id:"folder",value:this.state.folder},{id:"token",value:token}]} url={url} onSuccess={(result)=>{window.location='#/index/' + this.state.folder }} />}   
                </div>
            </div>
        );
    }
}